import { model as router } from '!/router'
import { Player } from '@setplex/pureya'
import './playerThemeVars.css'
import { type IPlayerWithCover } from './PlayerWithCover.h'

PlayerWithCover.displayName = 'PlayerWithCover'
export function PlayerWithCover({
  player,
  sourceUrl,
  title,
  locale,
  chromecast,
  cmcd,
  onPlaybackEnded,
  sendAnalyticsDataStartEventCallback,
  sendAnalyticsDataStopEventCallback,
  sendAnalyticsContentWatchingCallback,
  externalError,
  subtitle,
  drm,
  playlist,
  setLatestWatchedPosition,
  stoppedTime,
  disabled,
  children,
  isLiveUi,
  onReady,
  contentId,
  poster,
}: IPlayerWithCover) {
  return (
    // player requires variables `variables`
    // but they are the same as in arcana, so here player works without issues
    <div style={{ width: '100%', height: '100%' }}>
      <Player
        ref={player.ref}
        chromecast={chromecast}
        cmcd={cmcd}
        title={title}
        locale={locale}
        subtitle={subtitle}
        onReady={onReady}
        onPlaybackEnded={onPlaybackEnded}
        onReturnToPrevPageClick={router.navigateBack}
        onAnalyticsStartPlaying={sendAnalyticsDataStartEventCallback}
        onAnalyticsStopPausePlaying={sendAnalyticsDataStopEventCallback}
        onAnalyticsContentWatching={sendAnalyticsContentWatchingCallback}
        sourceUrl={sourceUrl}
        drm={drm}
        externalError={externalError}
        // test stream 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
        playlist={playlist}
        setLatestWatchedPosition={setLatestWatchedPosition}
        stoppedTime={stoppedTime}
        disabled={Boolean(disabled)}
        isLiveUi={isLiveUi}
        contentId={String(contentId)}
        poster={poster}
      >
        {children}
      </Player>
    </div>
  )
}
